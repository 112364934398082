<template>
  <v-row>
    <v-col>
      <v-dialog v-model="dialog" width="700" fullscreen>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" depressed v-bind="attrs" v-on="on" small>
            Añadir alumno <v-icon class="ml-2" small>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            <v-row class="align-center">
              <v-col> Añadir Alumno </v-col>
              <v-col cols="auto">
                <v-btn fab small depressed @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <p class="subtitle-1">Busca el alumno por NIF para proceder</p>
            <add-assistant-dialog-body
              :dialog="dialog"
              :centers="centers"
              :clients="clients"
              @addAssistant="addAssistant"
              @addNewStudentAssistant="addNewStudentAssistant"
              :adding-assistant="addingAssistant"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {
    addAssistantDialogBody: () =>
      import(
        "@/modules/cardioguard-trainer/components/views/trainer-session/AddAssistantDialogBody"
      ),
  },
  props: {
    centers: { type: Array, required: true },
    clients: { type: Array, required: true },
    trainingCourseId: { type: Number, required: true },
  },
  data: () => ({
    dialog: null,
    addingAssistant: null,
    currentService: null,
  }),
  beforeMount() {
    this.currentService = this.$store.state.currentService;
  },
  methods: {
    async addAssistant(studentId) {
      try {
        const payload = {
          studentId,
          trainingCourseId: this.trainingCourseId,
        };
        await this.currentService.addAssistant(payload);
        await this.$emit("getSessionData");
        this.dialog = false;
      } catch (e) {
        this.$notifyError(undefined, "Error al añadir el nuevo asistsnete | " + e);
      }
    },
    async addNewStudentAssistant(assistantData) {
      try {
        const payload = { ...assistantData, trainingCourseId: this.trainingCourseId };
        await this.currentService.addNewStudentAssistant(payload);
        await this.$emit("getSessionData");
        this.dialog = false;
      } catch (e) {
        this.$notifyError(undefined, "Error al añadir el nuevo asistsnete | " + e);
      }
    },
  },
};
</script>

<style scoped></style>
